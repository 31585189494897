import ApiService from './api.service'

export default class PatientService {


	async getPatients(_params) {
        const response = await ApiService.get('api/patients', _params)
        return response.data
    }

	async getPatientsForSelect(_params) {
        const response = await ApiService.get('api/patients/select', _params)
        return response.data.data
    }
    
	async getSocHomesForSelect(_params) {
        const response = await ApiService.get('api/patients/select/sochomes', _params)
        return response.data.data
    }
    
	async newPatient(patient) {
        const response = await ApiService.post('api/patients', patient)
        return response.data
    }

	async updatePatient(patient) {
        const response = await ApiService.put('api/patients/'+patient.id, patient)
        return response.data.id
    }

	async unRecordPatient(params) {
        const response = await ApiService.put('api/patients/unrecord/'+params.patient_id, params)
        return response.data.id
    }

	async deletePatient(id) {
        const response = await ApiService.delete('api/patients/'+id)
        return response
    }

    async getPatientDetails(id) {
        const response = await ApiService.get('api/patient/'+id)
        return response.data
    }

    async getPatientDoctors(id) {
        const response = await ApiService.get('api/patient/doctors/'+id)
        return response.data
    }

    async getPatientStatus(id) {
        const response = await ApiService.get('api/patient/status/'+id)
        return response.data
    }

    async newStatus(status) {
        const response = await ApiService.post('api/patient/status', status)
        return response.data.id
    }

	async updateStatus(status) {
        const response = await ApiService.post('api/patient/status/update', status)
        return response.data.id
    }

	async updateDoctor(doctors) {
        const response = await ApiService.post('api/patient/doctor/update', doctors)
        return response.data
    }
    

    // Illness
	async getIllcatsForSelect(_params) {
        const response = await ApiService.get('api/patient/illcat/select', _params)
        return response.data
    }
    
	async getBNOsForSelect(_params) {
        const response = await ApiService.get('api/patient/bno/select', _params)
        return response.data
    }
    
	async newIllness(illness) {
        const response = await ApiService.post('api/patient/illness', illness)
        return response.data.id
    }

	async updateIllness(illness) {
        const response = await ApiService.post('api/patient/illness/update', illness)
        return response.data.id
    }

	async deleteIllness(id) {
        const response = await ApiService.post('api/patient/illness/delete', { id })
        return response
    }

	async downloadVisitFile(fileId) {
        const requestData = {
            method: 'get',
            url: "/api/visits/download/",
            params: {
                id: fileId
            },
            responseType: 'blob',
        }
        const response = await ApiService.customRequest(requestData)
        return response
    }

	async uploadPatientFile(patientid, file) {
        let formData = new FormData()
        formData.append('patientid', patientid)
        formData.append('file', file)
        const response = await ApiService.post('api/patients/upload', formData)
        return response.data
    }

	async downloadPatientFile(fileId) {
        const requestData = {
            method: 'get',
            url: "/api/patients/download/",
            params: {
                id: fileId
            },
            responseType: 'blob',
        }
        const response = await ApiService.customRequest(requestData)
        return response
    }


}
