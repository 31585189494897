<template>
    <div class="fw-overlay-body" :style="megjelenit ? 'display: block' : 'display: none'"
        @show="dialogShow" @hide="hideDialog()" @keyup.esc="hideDialog()">
        <div id="fw-toolbar">
            <div id="fw-start">
                <!--cr-icon-button id="sidenavToggle" iron-icon="cr20:menu" title="Menü" aria-label="Menü" aria-expanded="false" aria-disabled="false" role="button" tabindex="0"></cr-icon-button-->
                <span id="fw-title">{{ fileName }}</span>
            </div>
            <div id="fw-center">
                <!--viewer-page-selector style="--page-length-digits:1;"></viewer-page-selector>
                <span class="vertical-separator"></span-->
                <span id="zoom-controls">
                    <!--cr-icon-button iron-icon="pdf:remove" title="Kicsinyítés" aria-label="Kicsinyítés" aria-disabled="false" role="button" tabindex="0"></cr-icon-button-->
                    <Button icon="pi pi-minus" @click="changeWidth(-1)" class="p-button-text" style="color:white"/>
                    <Dropdown v-model="percentWidth" :options="widthPercents" optionLabel="name" optionValue="value"/>
                    <Button icon="pi pi-plus" @click="changeWidth(1)" class="p-button-text" style="color:white"/>
                    <Button icon="pi pi-directions-alt" @click="changeRotation" class="p-button-text" style="color:white"/>
                    <!--cr-icon-button iron-icon="pdf:add" title="Nagyítás" aria-label="Nagyítás" aria-disabled="false" role="button" tabindex="0">
                    </cr-icon-button-->
                </span>
                <!--span class="vertical-separator"></span>
                <cr-icon-button id="fit" aria-label="Igazítás az oldalmérethez" title="Igazítás az oldalmérethez" aria-disabled="false" role="button" tabindex="0" iron-icon="pdf:fit-to-height"></cr-icon-button>
                <cr-icon-button iron-icon="pdf:rotate-left" dir="ltr" aria-label="Forgatás balra" title="Forgatás balra" aria-disabled="false" role="button" tabindex="0"></cr-icon-button-->
            </div>
            <div id="fw-end">
                <Button icon="pi pi-download" label="Letölt" @click="downloadFile" style="margin-right: 20px"/>
                <Button icon="pi pi-times" label="Bezár" @click="hideDialog"/>
            </div>
        </div>
        <div id="fw-content" @click="hideDialog()">
            <div class="fw-pdf-wrapper">
                <vue-pdf-embed v-if="isPdf" :source="source" :width="width" :rotation="rotation" @click.stop=""/>
                <img v-if="isImage" :src="source" :style="{'width': width + 'px'}" :class="'rotate' + rotation"/>
                <div v-if="!isPdf && !isImage" style='color: white; font-size: 16px; margin-top:100px'>Csak PDF vagy kép fájl jelentíthető meg!</div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive } from 'vue'
import VuePdfEmbed from 'vue-pdf-embed'

export default {
    components: { VuePdfEmbed },
    props: ['show','fileName','fileData'],
    data() {

        const megjelenit = reactive({})
        const widthPercents = [
            {'name': '25%', 'value': 0.25},
            {'name': '50%', 'value': 0.5},
            {'name': '75%', 'value': 0.75},
            {'name': '90%', 'value': 0.9},
            {'name': '100%', 'value': 1},
            {'name': '110%', 'value': 1.1},
            {'name': '125%', 'value': 1.25},
            {'name': '150%', 'value': 1.5},
            {'name': '175%', 'value': 1.75},
            {'name': '200%', 'value': 2},
            {'name': '300%', 'value': 3},
            {'name': '400%', 'value': 4}
        ]
        const rotationValues = [0,270,180,90]

        return {
            megjelenit,
            source: '',
            width: 1092,
            originalPdfWidth: 1092,
            percentWidth: 1,
            widthPercents,
            rotation: 0,
            rotationValues,
            isPdf: false,
            isImage: false
        }
    },

	created() {
        this.megjelenit = false
	},
    mounted() {
    },

    methods: {
        dialogShow() {
        },
        hideDialog() {
            this.megjelenit = false
            this.$emit('update:show', false)
        },
        getWidth() {
            return Math.max(
                document.body.scrollWidth,
                document.documentElement.scrollWidth,
                document.body.offsetWidth,
                document.documentElement.offsetWidth,
                document.documentElement.clientWidth
            )
        },
        changeWidth(param) {
            const searchIndex = this.widthPercents.findIndex((v) => v.value==this.percentWidth);
            const nextval = this.widthPercents[searchIndex+param].value ?? false
            if(nextval)
                this.percentWidth = nextval
        },
        changeRotation() {
            const searchIndex = this.rotationValues.findIndex((v) => v==this.rotation);
            const nextval = this.rotationValues[searchIndex+1] ?? false
            if(nextval)
                this.rotation = nextval
            else
                this.rotation = 0
        },
        downloadFile() {
            const link = document.createElement('a')
            link.href = this.fileData
            link.setAttribute('download', this.fileName)
            document.body.appendChild(link)
            link.click()
        }
    },
    watch: {
        show: function(v) {

            if(v){
                const x = this.fileName.slice(-3);
                if(x == 'pdf') this.isPdf = true
                if(x == 'jpg' || x == 'peg' || x == 'png' || x == 'bmp' || x == 'svg') this.isImage = true

                const w = this.getWidth();
                if(w < 1200)
                    this.width = w - 40;
                else
                    this.width = 1092;

                this.originalPdfWidth = this.width
                this.source = this.fileData                
            }else{
                this.source = ''
                this.isPdf = false
                this.isImage = false
            }
            this.megjelenit = v

        },
        percentWidth: function(v) {
            this.width = this.originalPdfWidth * v
        }
        /*fileData: function(v) {
            if(this.show){
                console.log(v)
                //this.source = this.v
            }
        },*/
    },
    computed: {
    }
}
</script>

<style scoped>

.fw-overlay-body{
    min-height: 100%;
    min-width: 100%;
    position: fixed;
    z-index: 1000000;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,.7);
}

#fw-toolbar {
    align-items: center;
    background-color: black;
    color: white;
    display: flex;
    height: 50px;
    padding: 0 16px;
}

#fw-start, #fw-end {
    flex: 1;
}

#fw-start {
    align-items: center;
    display: flex;
    overflow: hidden;
    padding-inline-end: 20px;
}

#fw-center, #fw-end {
    opacity: 1;
    visibility: visible;
}

#fw-center {
    align-items: center;
    display: flex;
}

#fw-end {
    display: flex;
    justify-content: flex-end;
    padding-inline-start: 20px;
    text-align: end;
    white-space: nowrap;
}

#fw-content {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

.fw-pdf-wrapper {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.rotate90 {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}
.rotate180 {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.rotate270 {
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
}
.rotate0 {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
}

</style>


<style>

/*.vue-pdf-embed canvas {
    width: auto !important;
    height: auto !important;
}*/

</style>
